<template>
  <b-card title="Ulangtahun">
    <VuePerfectScrollbar class="scrollbirth" :settings="settings" @ps-scroll-y="scrollHandle">
    <app-timeline>
      <app-timeline-item variant="secondary" icon="GiftIcon">
        <div
          class="
            d-flex
            flex-sm-row flex-column flex-wrap
            justify-content-between
            mb-1 mb-sm-0
          "
        >
          <h6>Hari ini</h6>
          <!-- <small class="text-muted">45 min ago</small> -->
        </div>
        <b-media v-for="bday in userBdaytoday" :key="bday.employee_full_name">
          <template #aside>
            <b-avatar :src="bday.thumbnail" />
          </template>
          <h6>{{ bday.employee_full_name }}</h6>
          <p class="mb-0">{{ bday.division }}</p>
          <p>{{ bday.unit }}</p>
        </b-media>
      </app-timeline-item>
    </app-timeline>
    </VuePerfectScrollbar>
  </b-card>
</template>

<script>
import {
  BImg,
  BAvatar,
  BCard,
  BMedia,
  BButton,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BImg,
    BMedia,
    BAvatar,
    BCard,
    BButton,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    VuePerfectScrollbar,
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },
  data() {
    return {
      userBdaytoday: [],
      settings: {
        maxScrollbarLength: 100,
      },
    };
  },
  mounted() {
    this.getBirthday();
  },
  methods: {
    getBirthday() {
      axios
        .get("/general/todayBirthDay")
        .then((response) => {
          // console.log(response.data.data.today);
          this.userBdaytoday = response.data.data.today;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    scrollHandle(evt) {
      // console.log(evt);
    },
  },
};
</script>
<style lang="scss">
.scrollbirth {
  position: relative;
  margin: auto;
  width: auto;
  height: 250px;
}
</style>
