<template>
  <div>
    <b-row class="match-height">
      <b-col lg="12">
        <card-birthday />
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <card-welcome />
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <card-shift-today />
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <user-birthday />
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <card-voucher-remaining />
      </b-col>
    </b-row>
    <!-- <b-card title="Kick start your project 🚀">
      <b-card-text>All the best for your new project.</b-card-text>
      <b-card-text>Please make sure to read our <b-link
        href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/"
        target="_blank"
      >
        Template Documentation
      </b-link> to understand where to go from here and how to use our template.</b-card-text>
    </b-card>

    <b-card title="Want to integrate JWT? 🔒">
      <b-card-text>We carefully crafted JWT flow so you can implement JWT with ease and with minimum efforts.</b-card-text>
      <b-card-text>Please read our  JWT Documentation to get more out of JWT authentication.</b-card-text>
    </b-card> -->
  </div>
</template>

<script>
import {
  BCard, BCardText, BLink, BRow, BCol,
} from 'bootstrap-vue'
import CardShiftToday from './card/CardShiftToday.vue'
import CardWelcome from './card/CardWelcome.vue'
import CardBirthday from './card/CardBirthday.vue'
import UserBirthday from './card/UserBirthday.vue'
import CardVoucherRemaining from './card/CardVoucherRemaining.vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    CardShiftToday,
    CardWelcome,
    CardBirthday,
    UserBirthday,
    CardVoucherRemaining,
},
}
</script>

<style>

</style>
