<template>
  <div>
    <b-card no-body class="card-developer-meetup">
      <div class="bg-light-primary rounded-top text-center">
        <b-img
          :src="require('@/assets/images/illustration/email.svg')"
          alt="Meeting Pic"
          height="170"
        />
      </div>
      <b-card-body>
        <!-- metting header -->
        <div class="meetup-header d-flex align-items-center mb-1">
          <div class="meetup-day">
            <h6 class="mb-0">
              {{ schedule_shift[0][mySchedule.schedule_shift_day] }}
            </h6>
            <h3 class="mb-0">
              {{ mySchedule.schedule_shift_date | moment("DD-MM-YYYY") }}
            </h3>
          </div>
          <div class="my-auto">
            <b-card-title class="mb-0">
              {{ mySchedule.shift_name }}
            </b-card-title>
            <b-card-text class="mb-0" v-if="mySchedule.shift_in_time != '-'">
              {{ mySchedule.shift_in_time }} - {{ mySchedule.shift_out_time }}
            </b-card-text>
          </div>
        </div>
        <!--/ metting header -->

        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-primary" size="34">
              <feather-icon icon="ClockIcon" size="18" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              Lihat
              <b-link href="#" class="card-link" @click="attendanceLog()"
                >Log</b-link
              >
            </h6>
            <small>Klik pada log untuk melihat absensi</small>
          </b-media-body>
        </b-media>

        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-primary" size="34">
              <feather-icon icon="CalendarIcon" size="18" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              {{ schedule_shift[0][tomorrow.schedule_shift_day] }} -
              {{ tomorrow.schedule_shift_date | moment("DD-MM-YYYY") }}
            </h6>
            <small>{{ tomorrow.shift_name }} </small>
            <small v-if="tomorrow.shift_name != 'Off Day'"
              >| {{ tomorrow.shift_in_time }} -
              {{ tomorrow.shift_out_time }}</small
            >
          </b-media-body>
        </b-media>

        <b-avatar-group class="mt-2 pt-50" size="33">
          <b-avatar
            v-for="partner in partners"
            :key="partner.index"
            v-b-tooltip.hover.bottom="partner.emp_full_name"
            :src="'https://hrs.baliroyalhospital.co.id' + partner.thumbs"
            class="pull-up"
          />
        </b-avatar-group>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-sm"
      ref="AttLog"
      cancel-variant="secondary"
      hide-footer
      centered
      size="sm"
      title="Attendance Log"
    >
      <b-row v-if="vLog.length">
        <b-col md="12" sm="12" v-for="(log, index) in vLog" :key="index">
          <b-card no-body>
            <b-card-header class="align-items-baseline">
              <div>
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-info">
                      <feather-icon size="24" icon="ClockIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <b-card-title class="mb-1">{{
                      stdDate(log.scan_date)
                    }}</b-card-title>
                    <b-card-sub-title class="mb-0 font-italic">
                      {{ log.scan_time }}
                    </b-card-sub-title>
                  </b-media-body>
                </b-media>
              </div>
            </b-card-header>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <h5>Data tidak ditemukan</h5>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BImg,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BCardHeader,
  BMedia,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BMediaBody,
  BLink,
  BModal,
  BRow,
  BCol,
} from "bootstrap-vue";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BLink,
    BModal,
    BRow,
    BCol,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      mySchedule: [],
      attLog: [],
      partners: [],
      employeeData: JSON.parse(localStorage.getItem("userData")),
      schedule_shift: [
        {
          Monday: "Senin",
          Tuesday: "Selasa",
          Wednesday: "Rabu",
          Thursday: "Kamis",
          Friday: "Jumat",
          Saturday: "Sabtu",
          Sunday: "Minggu",
        },
      ],
      vLog: [],
      tomorrow: {},
    };
  },
  mounted() {
    this.getScheduleToday();
    this.getMySchedule();
  },
  methods: {
    stdDate(date) {
      moment.locale("id");

      return moment(date).format("dddd, DD MMMM YYYY");
    },
    getScheduleToday() {
      axios
        .post("/employee/mySchedulePartner", {
          employee_code: this.employeeData.employee_code,
          only_my_units: true,
        })
        .then((response) => {
          //console.log(response.data.data);
          this.mySchedule = response.data.data.mySchedule;
          this.partners = response.data.data.partner;
        })
        .catch((error) => {
          //console.log(error.response.status);
          if (error.response.status === 400) {
            console.log(error);
          }
          if (error.response.status === 401) {
            this.$router.push({ name: "not-authorized" }).catch(() => {});
          } else {
            console.log(error);
          }
        });
    },
    getMySchedule() {
      axios
        .post("/employee/findMySchedule", {
          employee_code: this.employeeData.employee_code,
        })
        .then((response) => {
          // console.log(response.data.data);
          this.tomorrow = response.data.data.tomorrow;
        });
    },
    attendanceLog() {
      this.$refs["AttLog"].show();
      axios
        .post("employee/myAttendanceScanLog", {
          employee_code: this.employeeData.employee_code,
        })
        .then((response) => {
          console.log(response);
          this.vLog = response.data.data;
        });
    },
  },
};
</script>
