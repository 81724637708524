<template>
  <div>
    <b-card no-body class="card-statistics">
      <b-card-header>
        <b-card-title>Voucher Statistics</b-card-title>
        <b-card-text class="mr-25 mb-0">
          <b-link href="#" class="alert-link" @click="showRemaining()"
            >List</b-link
          >
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col md="8" sm="6" class="mb-4 mb-md-1">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="info">
                  <feather-icon size="24" icon="SmileIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ vStats.voucher_total }} Voucher
                </h4>
                <b-card-text class="font-small-3 mb-0"> Total </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="8" sm="6" class="mb-4 mb-md-1">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="success">
                  <feather-icon size="24" icon="CheckCircleIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ vStats.voucher_available }} Voucher
                </h4>
                <b-card-text class="font-small-3 mb-0"> Tersedia </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="8" sm="6" class="mb-4 mb-md-1">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="danger">
                  <feather-icon size="24" icon="XCircleIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ vStats.voucher_taken }} Voucher
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Sudah Digunakan
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-modal
      id="modal-lg"
      ref="voucherRemaining"
      ok-only
      ok-title="Accept"
      hide-footer
      centered
      size="sm"
      title="Voucher Cuti"
    >
        <b-row>
          <!-- <VuePerfectScrollbar
            class="scrollvoucher"
            :settings="settings"
            @ps-scroll-y="scrollHandle"
          > -->
          <b-col
            md="12"
            sm="12"
            v-for="(remain, index) in vRemaining"
            :key="index"
          >
            <b-card no-body>
              <b-card-header class="align-items-baseline">
                <div>
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar
                        size="48"
                        variant="danger"
                        v-if="remain.claim_status == 1"
                      >
                        <feather-icon size="24" icon="XCircleIcon" />
                      </b-avatar>
                      <b-avatar size="48" variant="success" v-else>
                        <feather-icon size="24" icon="CheckCircleIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <b-card-title class="mb-1">{{
                        remain.claim_code
                      }}</b-card-title>
                      <b-card-sub-title
                        class="mb-0 font-italic"
                        v-if="remain.claim_status == 1"
                      >
                        Diambil pada {{ longDate(remain.claim_date) }}
                      </b-card-sub-title>
                      <b-card-sub-title class="mb-0 font-italic" v-else>
                        Belum Diambil
                      </b-card-sub-title>
                    </b-media-body>
                  </b-media>
                </div>
              </b-card-header>
            </b-card>
          </b-col>
      <!-- </VuePerfectScrollbar> -->
        </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BCardHeader,
  BLink,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BModal,
} from "bootstrap-vue";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BCardHeader,
    BLink,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BModal,
    VuePerfectScrollbar,
  },
  data() {
    return {
      employeeData: JSON.parse(localStorage.getItem("userData")),
      vRemaining: [],
      vStats: [],
      settings: {
        maxScrollbarLength: 100,
      },
    };
  },
  mounted() {
    this.voucherRemaining();
  },
  methods: {
    longDate(date) {
      moment.locale("id");

      return moment(date).format("dddd, DD MMMM  YYYY h:mm a");
    },
    voucherRemaining() {
      axios
        .post("/employee/myLeaveVoucher", {
          employee_code: this.employeeData.employee_code,
        })
        .then((response) => {
          //console.log(response);
          this.vRemaining = response.data.data.voucher_list;
          this.vStats = response.data.data;
        });
    },
    showRemaining() {
      this.$refs["voucherRemaining"].show();
    },
    scrollHandle(evt) {
      //console.log(evt);
    },
  },
};
</script>
<!-- <style lang="scss">
.scrollvoucher {
  position: relative;
  margin: auto;
  width: auto;
  height: auto;
}
</style> -->