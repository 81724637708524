<template>
    <div >
        <b-card
            text-variant="center"
            class="card card-congratulations"
            v-for="(bday) in userBday" :key="bday.employee_full_name" v-show="user.employee_name == bday.employee_full_name"
        >
                    <!-- images -->
                    <b-img
                    :src="require('@/assets/images/elements/decore-left.png')"
                    class="congratulations-img-left"
                    />
                    <b-img
                    :src="require('@/assets/images/elements/decore-right.png')"
                    class="congratulations-img-right"
                    />
                    <!--/ images -->

                    <b-avatar
                    variant="primary"
                    size="70"
                    class="shadow mb-2"
                    >
                    <feather-icon
                        size="28"
                        icon="GiftIcon"
                    />
                    </b-avatar>
                    <h1 class="mb-1 mt-50 text-white" >
                    Selamat Ulang tahun {{ user.employee_name }}  yang ke - {{bday.age}}
                    </h1>
            <!-- <b-card-text class="m-auto w-75">
            Selamat datang di <strong>HRS Personal</strong>. Ini adalah program <strong>ANTI RIBET ADMINISTRASI</strong> silahkan dipergunakan dengan bijak.
            </b-card-text> -->
        </b-card>
    </div>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
  data(){
    return {
        userBday: []
    }
  },
  mounted(){
    this.getBirthday()
  },
  methods:{
    getBirthday() {
        axios.get("/general/todayBirthDay").then(response =>{
            //console.log(response.data.data.today);
            this.userBday = response.data.data.today
        }).catch(error => {
            console.log(error);
        })
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      user: 'auth/userData',
    }),
  },
}
</script>
